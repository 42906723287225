import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useDispatch, useSelector } from 'react-redux';

import { IStoreState } from '@type/index';

import { ThemeProps } from '@theme/ThemeProps.model';

import {
  ACCOUNT_ACTION,
  ACTIVATION,
  ERROR_CODE,
  RESET_PASSWORD,
  SOCIAL_LINKS,
  RADIOPOPUP_FORBIDDEN_LINKS
} from '@constants/constants';

import {
  injectSheet,
  getContentfulService,
  gigyaScreenSets,
  NotificationPopup,
  Modal,
  Toaster,
  Login,
  showScreenSet,
  setUser,
  unsetUser,
  checkUser,
  Player,
  getAppRoute,
  getVotingRoute,
  RadioPopup,
  showMiniPlayer,
  radioplayerEventBus
} from '@imports';

import {
  showLogin,
  showRegistration
} from '@components/Login/Authenticator/Authenticator';

import { useGigyaContext } from '../../contexts/gigya/gigyaContext';

import styles from './Layout.styles';

const Navigation: React.ComponentType<{
  menuId: any;
  getMenuData: any;
  mobileLogo: boolean;
  socialLinks: typeof SOCIAL_LINKS;
  customLogo: any;
  setHasNavItems?: any;
  shouldHideMenuItems: boolean;
  userScrolledDown?: (...args: any[]) => any;
  setIsOnSearchPage: (...args: any[]) => any;
  isSearchPage: boolean;
}> = dynamic(
  () => import('@talpanetwork/talpa-publishing-components/Navigation'),
  { ssr: true }
);

const Footer: React.ComponentType<{
  footerId: any;
  getFooterData: any;
}> = dynamic(() => import('@talpanetwork/talpa-publishing-components/Footer'), {
  ssr: true
});

const Sidebar: React.ComponentType<{
  showMiniPlayer: () => any;
  radioplayerEventBus: any;
  player: any;
  closePlayer: () => any;
}> = dynamic(
  () => import('@talpanetwork/talpa-publishing-components/Sidebar'),
  {
    ssr: true
  }
);

export type LayoutProps = ThemeProps & { children: any; location?: any };

const isLoggedIn = (auth: any): boolean =>
  Boolean(auth && Object.keys(auth).length !== 0);

const showToaster = (auth: any): boolean => {
  if (typeof window !== 'undefined') {
    return (
      isLoggedIn(auth) && !window.localStorage.getItem('finishedLoginFlow')
    );
  }
  return false;
};

const Layout = (props: LayoutProps) => {
  const [hasNavItems, setHasNavItems] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);
  const [isSearchPage, setIsSearchPage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPlayerExpanded, setIsPlayerExpanded] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState<string | null>(
    null
  );

  const appRoute = getAppRoute();
  const votingRoute = getVotingRoute();

  const data = useSelector((state: IStoreState) => state.landing);
  const auth = useSelector((state: IStoreState) => state.auth);

  const dispatch = useDispatch();

  const { isGigyaLoaded } = useGigyaContext();

  const {
    menuId,
    theme,
    children,
    classes,
    footerId,
    alternateBrandLogo,
    toasterTitle,
    toasterBody,
    toasterLinkText,
    toasterLink,
    verificationModalFields,
    radioPopupFields
  } = {
    ...data,
    ...props
  };

  const isPc = theme.device === 'pc';
  const isMobile = theme.device === 'mobile';
  const contentInnerClass = classNames(
    classes?.contentInner,
    {
      [classes?.hasNavItems]:
        !scrollDown && !isSearchPage && !appRoute && !votingRoute
    },
    {
      [classes?.appClass]: appRoute
    }
  );
  const contentHolder = classNames(classes.contentHolder, {
    [classes.appContentHolder]: appRoute,
    [classes.votingContentHolder]: votingRoute
  });

  const content = getContentfulService();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const modalAction = () => {
    if (verificationStatus === '0') {
      gigyaScreenSets.checkUserStatus(showLogin);
    } else {
      gigyaScreenSets.showVerificationPending();
      showScreenSet(process.env.NEXT_PUBLIC_SCREENSET_CONTAINER);
    }
    setIsModalOpen(false);
  };

  const setUserDispatch = (gigya) => {
    dispatch(setUser(gigya));
  };

  const unsetUserDispatch = () => {
    dispatch(unsetUser());
  };

  const checkGigyaScreenSetsPopUp = () => {
    gigyaScreenSets.setSocialLogoutHandler(unsetUserDispatch);
    gigyaScreenSets.setSocialLoginHandler(setUserDispatch);

    const { search, hash } = window.location;

    if (search.includes(ACCOUNT_ACTION)) {
      const urlParams = new URLSearchParams(search);
      const paramValue = urlParams.get(ACCOUNT_ACTION);

      if (paramValue === RESET_PASSWORD) {
        gigyaScreenSets.showResetPassword();
      } else if (paramValue === ACTIVATION) {
        setIsModalOpen(true);
        setVerificationStatus(urlParams.get(ERROR_CODE));
      }
    }

    switch (hash) {
      case '#optin':
        window.location.href = '/nieuwsbrief';
        break;
      case '#login':
        gigyaScreenSets.checkUserStatus(showLogin);
        break;
      case '#register':
        gigyaScreenSets.checkUserStatus(showRegistration);
        break;
    }

    dispatch(checkUser(gigyaScreenSets));
  };

  useEffect(() => {
    if (isGigyaLoaded) {
      checkGigyaScreenSetsPopUp();
    }
    if (typeof window !== 'undefined') {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  useEffect(() => {
    if (isGigyaLoaded) {
      checkGigyaScreenSetsPopUp();
    }
  }, [isGigyaLoaded]);

  const userScrolledDown = (hasScrolledDown: boolean) => {
    if (hasNavItems && !isPc && hasScrolledDown !== scrollDown) {
      setScrollDown(hasScrolledDown);
    }
  };

  const setIsOnSearchPage = (onSearchPage: boolean) => {
    setIsSearchPage(onSearchPage);
  };

  const closeRadioPlayer = () => {
    const url = window.location.origin + window.location.pathname;
    window.history.replaceState({}, '', url);

    dispatch(showMiniPlayer());
    setIsPlayerExpanded(false);
  };

  const backdropClass = classNames(classes.backdrop, {
    [classes.showBackdrop]: isPlayerExpanded
  });
  const navigationWrapperClass = classNames(classes?.navigationWrapper, {
    [classes?.searchPageNavigationWrapper]: isSearchPage
  });

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,maximum-scale=1,user-scalable=no"
        />
      </Head>
      <div className={classes?.layoutHolder}>
        {!appRoute && !votingRoute && (
          <Sidebar
            showMiniPlayer={showMiniPlayer}
            radioplayerEventBus={radioplayerEventBus}
            player={<Player setIsPlayerExpanded={setIsPlayerExpanded} />}
            closePlayer={closeRadioPlayer}
          />
        )}
        <NotificationPopup />
        {showToaster(auth) && (
          <Toaster
            toasterTitle={toasterTitle}
            toasterBody={toasterBody}
            toasterLinkText={toasterLinkText}
            toasterLink={toasterLink}
          />
        )}
        {!appRoute && !votingRoute && (
          <div className={classes?.navigationHolder}>
            {isMobile && (
              <div className={backdropClass} onClick={closeRadioPlayer} />
            )}

            <div className={navigationWrapperClass}>
              <div id="navWrapper">
                <Navigation
                  menuId={menuId}
                  getMenuData={content.getMenuNext}
                  mobileLogo={props.theme?.deviceType === 'mobile'}
                  socialLinks={SOCIAL_LINKS}
                  customLogo={alternateBrandLogo}
                  setHasNavItems={setHasNavItems}
                  shouldHideMenuItems={isPlayerExpanded}
                  userScrolledDown={userScrolledDown}
                  setIsOnSearchPage={setIsOnSearchPage}
                  isSearchPage={isSearchPage}
                />
                <Login />
              </div>
            </div>
          </div>
        )}
        {!votingRoute && (
          <Modal
            modalContent={{
              ...verificationModalFields,
              verificationStatus
            }}
            isOpen={isModalOpen}
            handleClose={closeModal}
            handleAction={modalAction}
          />
        )}

        {!isMobile && !votingRoute && (
          <RadioPopup
            modalContent={radioPopupFields}
            theme={theme}
            forbiddenList={RADIOPOPUP_FORBIDDEN_LINKS}
          />
        )}
        <div className={contentHolder}>
          <div className={contentInnerClass}>
            {children}
            {!appRoute && !votingRoute && (
              <Footer footerId={footerId} getFooterData={content.getFooter} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default injectSheet(styles)(Layout);
